import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { DashboardLayout } from '../modules/layout/components/dashboard-layout';
import { ISoloCharacter } from '../modules/common/model/graphql-types';
import { getImage, GatsbyImage, StaticImage } from 'gatsby-plugin-image';

import './employee.scss';
import './solo-character-dynamic.scss';
import { Col, Row } from 'react-bootstrap';
import { Seo } from '../modules/common/components/seo';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faCircleInfo,
  faDiagramProject,
  faSquare
} from '@fortawesome/free-solid-svg-icons';
import { RatingBox } from '../modules/common/components/rating-box';
import { SoloArtifact } from '../modules/solo/common/components/solo-artifact';
import { SoloJewelry } from '../modules/solo/common/components/solo-jewelry';
import { SoloWeapon } from '../modules/solo/common/components/solo-weapon';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

interface ISoloCharacterNodes {
  nodes: ISoloCharacter[];
}

interface ISoloCharacterEntry {
  currentUnit: ISoloCharacterNodes;
}

interface IProps {
  data: ISoloCharacterEntry;
}

const SoloCharacterDetails: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];
  const [currentTab, setCurrentTab] = useState('Info');

  const profileImage = character.cardImage
    ? getImage(character.cardImage.localFile.childImageSharp)
    : null;

  return (
    <DashboardLayout
      className={'generic-page character-page character-solo '}
      game="solo"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/solo-leveling/">Solo Leveling: Arise</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/solo-leveling/characters">Characters</Link>
        </li>
        <li className="divider">/</li>
        <li>{character.name}</li>
      </ul>
      <>
        <div className="character-top">
          <div className="left-info">
            <h1>
              <span className="small">Solo Leveling: Arise</span>
              <strong className={`rarity rar-${character.rarity}`}>
                {character.name}
              </strong>
              <span className="sub">Information and Guide</span>
            </h1>
          </div>
          <div className="right-image">
            <div
              className={`character-background rarity rar-${character.rarity} ${character.slug}`}
            ></div>
            <GatsbyImage image={profileImage} alt="Character" />
          </div>
        </div>
        <Row className="intro-section">
          <Col xs={12} xl={12}>
            <div className={`content-header rarity rar-${character.rarity}`}>
              <FontAwesomeIcon icon={faSquare} width="18" /> Introduction
            </div>
            <div className="character-intro">
              <div className="combined">
                <h2>
                  <strong>{character.name}</strong> is a{' '}
                  <strong className={`rarity rar-${character.rarity}`}>
                    {character.rarity}
                  </strong>{' '}
                  rarity character in Solo Leveling: Arise.{' '}
                  <strong>{character.name}</strong> belongs to the
                  {character.class === 'Mage' && (
                    <>
                      <StaticImage
                        src="../images/solo/icons/class_mage.webp"
                        alt="Mage"
                        className="faction-icon"
                      />
                    </>
                  )}
                  {character.class === 'Fighter' && (
                    <>
                      <StaticImage
                        src="../images/solo/icons/class_fighter.webp"
                        alt="Fighter"
                        className="faction-icon"
                      />
                    </>
                  )}
                  {character.class === 'Healer' && (
                    <>
                      <StaticImage
                        src="../images/solo/icons/class_healer.webp"
                        alt="Healer"
                        className="faction-icon"
                      />
                    </>
                  )}
                  {character.class === 'Tank' && (
                    <>
                      <StaticImage
                        src="../images/solo/icons/class_tank.webp"
                        alt="Tank"
                        className="faction-icon"
                      />
                    </>
                  )}
                  {character.class === 'Ranger' && (
                    <>
                      <StaticImage
                        src="../images/solo/icons/class_ranger.webp"
                        alt="Ranger"
                        className="faction-icon"
                      />
                    </>
                  )}
                  {character.class === 'Assassin' && (
                    <>
                      <StaticImage
                        src="../images/solo/icons/class_assassin.webp"
                        alt="Assassin"
                        className="faction-icon"
                      />
                    </>
                  )}{' '}
                  <strong>{character.class}</strong> class and uses the{' '}
                  {character.element === 'Water' && (
                    <>
                      <StaticImage
                        src="../images/solo/icons/ele_water.webp"
                        alt="Water"
                        className="faction-icon"
                      />
                    </>
                  )}
                  {character.element === 'Wind' && (
                    <>
                      <StaticImage
                        src="../images/solo/icons/ele_wind.webp"
                        alt="Wind"
                        className="faction-icon"
                      />
                    </>
                  )}
                  {character.element === 'Fire' && (
                    <>
                      <StaticImage
                        src="../images/solo/icons/ele_fire.webp"
                        alt="Fire"
                        className="faction-icon"
                      />
                    </>
                  )}
                  {character.element === 'Light' && (
                    <>
                      <StaticImage
                        src="../images/solo/icons/ele_light.webp"
                        alt="Light"
                        className="faction-icon"
                      />
                    </>
                  )}
                  {character.element === 'Dark' && (
                    <>
                      <StaticImage
                        src="../images/solo/icons/ele_darkness.webp"
                        alt="Dark"
                        className="faction-icon"
                      />
                    </>
                  )}{' '}
                  <strong>{character.element}</strong> element.
                </h2>
              </div>
              <p className="hide-on-mobile">
                To learn more about{' '}
                <strong className={`rarity rar-${character.rarity}`}>
                  {character.name}
                </strong>{' '}
                check the sections below. <strong>Use the tabs</strong> to
                quickly switch to the kind of information you're looking for.
              </p>
            </div>
          </Col>
        </Row>
        <>
          <div className="build-relics">
            <div className={`content-header rarity rar-${character.rarity}`}>
              <FontAwesomeIcon icon={faSquare} width="18" /> Last updated
            </div>
            <div className="info-box with-margin">
              <p>
                <strong className={`rarity rar-${character.rarity}`}>
                  {character.name}
                </strong>{' '}
                profile has been last updated at:{' '}
                <strong>{character.updatedAt}</strong>.
              </p>
            </div>
          </div>
        </>
        <div
          className="fuse-ad-placeholder bigger"
          data-fuse="22844297232"
        ></div>
        <p className="show-on-mobile">
          To learn more about{' '}
          <strong className={`rarity rar-${character.rarity}`}>
            {character.name}
          </strong>{' '}
          check the sections below. <strong>Use the tabs</strong> to quickly
          switch to the kind of information you're looking for.
        </p>
        <div className="tabs">
          <div
            className={`single-tab  rarity rar-${character.rarity} ${
              currentTab === 'Info' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Info')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
            </div>
            <p>Profile</p>
          </div>
          <div
            className={`single-tab rarity rar-${character.rarity} ${
              currentTab === 'Review' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Review')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faBook} width="18" />
            </div>
            <p>Review</p>
          </div>
          <div
            className={`single-tab rarity rar-${character.rarity} ${
              currentTab === 'Build' ? 'active' : ''
            }`}
            onClick={() => setCurrentTab('Build')}
          >
            <div className="top-icon">
              <FontAwesomeIcon icon={faDiagramProject} width="18" />
            </div>
            <p>Build info</p>
          </div>
        </div>
        <div className={`tab-inside ${currentTab === 'Info' ? 'active' : ''}`}>
          <div className={`mobile-header rarity rar-${character.rarity}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Profile
            </p>
          </div>
          <div className={`content-header rarity rar-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Passive
          </div>
          {character.passive ? (
            <>
              <div className={`skills`}>
                <Row xs={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div
                          className={`skill-icon rarity rar-${character.rarity}`}
                        >
                          Passive
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">
                            <strong>{character.name}</strong> Passive
                          </p>
                        </div>
                      </div>
                      <div className={`skill-description`}>
                        <>{renderRichText(character.passive, options)}</>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`rarity rar-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>passive information aren't available yet.</strong>{' '}
                  They will be added soon!
                </p>
              </div>
            </>
          )}
          <div className={`content-header rarity rar-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Advancements
          </div>
          {character.dupes ? (
            <>
              <div className={`skills`}>
                <Row xs={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div
                          className={`skill-icon rarity rar-${character.rarity}`}
                        >
                          Advancements
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">
                            <strong>{character.name}</strong> Advancement
                            passives
                          </p>
                        </div>
                      </div>
                      <div className={`skill-description`}>
                        <p className="stars">
                          &#10022; &#10023; &#10023; &#10023; &#10023;
                        </p>
                        <p
                          className="dupe-info"
                          dangerouslySetInnerHTML={{
                            __html: character.dupes.dupe_1
                          }}
                        />
                        <p className="stars">
                          &#10022; &#10022; &#10023; &#10023; &#10023;
                        </p>
                        <p
                          className="dupe-info"
                          dangerouslySetInnerHTML={{
                            __html: character.dupes.dupe_2
                          }}
                        />
                        <p className="stars">
                          &#10022; &#10022; &#10022; &#10023; &#10023;
                        </p>
                        <p
                          className="dupe-info"
                          dangerouslySetInnerHTML={{
                            __html: character.dupes.dupe_3
                          }}
                        />
                        <p className="stars">
                          &#10022; &#10022; &#10022; &#10022; &#10023;
                        </p>
                        <p
                          className="dupe-info"
                          dangerouslySetInnerHTML={{
                            __html: character.dupes.dupe_4
                          }}
                        />
                        <p className="stars">
                          &#10022; &#10022; &#10022; &#10022; &#10022;
                        </p>
                        <p
                          className="dupe-info"
                          dangerouslySetInnerHTML={{
                            __html: character.dupes.dupe_5
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`rarity rar-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>
                    advancements information aren't available yet.
                  </strong>{' '}
                  They will be added soon!
                </p>
              </div>
            </>
          )}
          <div className={`content-header rarity rar-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> EX Weapon
          </div>
          {character.dupes ? (
            <>
              <div className={`skills`}>
                <Row xs={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div
                          className={`skill-icon rarity rar-${character.rarity}`}
                        >
                          EX Weapon
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">
                            <strong>{character.exWeaponName}</strong>
                          </p>
                        </div>
                      </div>
                      <div className={`skill-description`}>
                        <>{renderRichText(character.exWeaponInfo, options)}</>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`rarity rar-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>EX Weapon information aren't available yet.</strong>{' '}
                  They will be added soon!
                </p>
              </div>
            </>
          )}
          <div className={`content-header rarity rar-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Stats (level max)
          </div>
          <Row xs={1} xxl={4} className="va-section">
            <Col>
              <div className="info-list-row">
                <div className="category">Power</div>
                <div className="details">
                  <strong className={`rarity rar-${character.rarity}`}>
                    {character.stats.power}
                  </strong>
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">HP</div>
                <div className="details">
                  <strong className={`rarity rar-${character.rarity}`}>
                    {character.stats.hp}
                  </strong>
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">DEF</div>
                <div className="details">
                  <strong className={`rarity rar-${character.rarity}`}>
                    {character.stats.def}
                  </strong>
                </div>
              </div>
            </Col>
            <Col>
              <div className="info-list-row">
                <div className="category">ATK</div>
                <div className="details">
                  <strong className={`rarity rar-${character.rarity}`}>
                    {character.stats.atk}
                  </strong>
                </div>
              </div>
            </Col>
          </Row>
          <div className={`content-header rarity rar-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Skills
          </div>
          <div className="info-box">
            <p>
              <strong className={`rarity rar-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>skills aren't available yet.</strong> They will be added
              soon.
            </p>
          </div>
          <div className={`content-header rarity rar-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Gallery
          </div>
          <div className="info-box">
            <p>
              <strong className={`rarity rar-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>images aren't available yet.</strong> They will be added
              soon.
            </p>
          </div>
        </div>
        <div
          className={`tab-inside ${currentTab === 'Review' ? 'active' : ''}`}
        >
          <div className={`mobile-header rarity rar-${character.rarity}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Review
            </p>
          </div>
          <div className={`content-header rarity rar-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Overview
          </div>
          {character.review ? (
            <div className="section-analysis">
              <div className={`review raw`}>
                {character.review ? (
                  <>{renderRichText(character.review, options)}</>
                ) : (
                  <p>Overview is pending.</p>
                )}
              </div>
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`rarity rar-${character.rarity}`}>
                  {character.name}
                </strong>{' '}
                <strong>overview isn't available yet.</strong> It will be added
                as soon as possible!
              </p>
            </div>
          )}
          <div className={`content-header rarity rar-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Pros & Cons
          </div>
          {character.pros ? (
            <div className="section-analysis">
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      {renderRichText(character.pros, options)}
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      {renderRichText(character.cons, options)}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`rarity rar-${character.rarity}`}>
                  {character.name}
                </strong>{' '}
                <strong>pros & cons aren't available yet.</strong> They will be
                added when the character is released.
              </p>
            </div>
          )}
          <div className={`content-header rarity rar-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Review
          </div>
          {character.fullReview ? (
            <div className="section-analysis">
              <div className={`review raw`}>
                {character.fullReview ? (
                  <>{renderRichText(character.fullReview, options)}</>
                ) : (
                  <p>Review is pending.</p>
                )}
              </div>
            </div>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`rarity rar-${character.rarity}`}>
                  {character.name}
                </strong>{' '}
                <strong>review isn't available yet.</strong> It will be added as
                soon as possible!
              </p>
            </div>
          )}
          <div className={`content-header rarity rar-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Ratings
          </div>
          {character.ratings.pve != 1 ? (
            <>
              <p>
                <strong className={`rarity rar-${character.rarity}`}>
                  {character.name}
                </strong>{' '}
                rating takes into account their performance in all matter of PVE
                content.
              </p>
              <div className="ratings-container">
                <RatingBox
                  game="nikke"
                  rating={character.ratings.pve}
                  ratingName="PVE"
                />
              </div>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`rarity rar-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>ratings aren't available yet.</strong> They will be
                  added soon.
                </p>
              </div>
            </>
          )}
        </div>
        <div className={`tab-inside ${currentTab === 'Build' ? 'active' : ''}`}>
          <div className={`mobile-header rarity rar-${character.rarity}`}>
            <p>
              <FontAwesomeIcon icon={faCircleInfo} width="18" />
              Build info
            </p>
          </div>
          <div className={`content-header rarity rar-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Burning Series Sets
          </div>
          {character.slug != 'harper' ? (
            <>
              <div className="burning-sets">
                {character.specialSet
                  .toString()
                  .split(',')
                  .map((type, index) => (
                    <div className={`type`} key={index}>
                      {type === 'Blessing' && (
                        <>
                          <div className="info-box">
                            <StaticImage
                              src="../images/solo/artifacts/armor_blessing.webp"
                              alt="Burning Blessing"
                            />
                            <h5>Burning Blessing</h5>
                            <p>
                              <strong
                                className={`rarity rar-${character.rarity}`}
                              >
                                {character.name}
                              </strong>{' '}
                              can be equipped with the{' '}
                              <strong>Burning Blessing set</strong> that you can
                              obtain from the Workshop of Brilliant Light. As a
                              Support,{' '}
                              <strong
                                className={`rarity rar-${character.rarity}`}
                              >
                                {character.name}
                              </strong>{' '}
                              can make use of the set to help his party members
                              and it has priority over the normal sets.
                            </p>
                          </div>
                        </>
                      )}
                      {type === 'Curse' && (
                        <>
                          <div className="info-box">
                            <StaticImage
                              src="../images/solo/artifacts/armor_curse.webp"
                              alt="Burning Curse"
                            />
                            <h5>Burning Curse</h5>
                            <p>
                              <strong
                                className={`rarity rar-${character.rarity}`}
                              >
                                {character.name}
                              </strong>{' '}
                              can be equipped with the{' '}
                              <strong>Burning Curse set</strong> that you can
                              obtain from the Workshop of Brilliant Light. As a
                              Damage Dealer,{' '}
                              <strong
                                className={`rarity rar-${character.rarity}`}
                              >
                                {character.name}
                              </strong>{' '}
                              can make use of the set and in most cases, it will
                              perform better than the normal sets (2 piece is
                              minimum, but you want all 8).
                            </p>
                          </div>
                        </>
                      )}
                      {type === 'Greed' && (
                        <>
                          <div className="info-box">
                            <StaticImage
                              src="../images/solo/artifacts/armor_greed.webp"
                              alt="Burning Greed"
                            />
                            <h5>Burning Greed</h5>
                            <p>
                              <strong
                                className={`rarity rar-${character.rarity}`}
                              >
                                {character.name}
                              </strong>{' '}
                              can be equipped with the{' '}
                              <strong>Burning Greed set</strong> that you can
                              obtain from the Workshop of Brilliant Light. As a
                              Breaker,{' '}
                              <strong
                                className={`rarity rar-${character.rarity}`}
                              >
                                {character.name}
                              </strong>{' '}
                              can make use of the set and in most cases, it will
                              perform better than the normal sets (4 piece is
                              minimum, but you want all 8).
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <div className="info-box">
              <p>
                <strong className={`rarity rar-${character.rarity}`}>
                  {character.name}
                </strong>{' '}
                <strong>artifact sets info aren't available yet.</strong> They
                will be added soon.
              </p>
            </div>
          )}
          <div className={`content-header rarity rar-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Artifact Sets
          </div>
          {character.artifacts.set_1 != 0 ? (
            <>
              <Row xs={1} xl={2} xxl={2} className="artifact-tips">
                <Col>
                  <h5>Armor Set</h5>
                  <SoloArtifact id={character.artifacts.set_1} profile />
                  {character.artifacts.set_2 != 0 && (
                    <SoloArtifact id={character.artifacts.set_2} profile />
                  )}
                  {character.artifacts.set_3 != 0 && (
                    <SoloArtifact id={character.artifacts.set_3} profile />
                  )}
                </Col>
                <Col>
                  <h5>Jewelry Set</h5>
                  <SoloJewelry id={character.jewelry.set_1} profile />
                  {character.jewelry.set_2 != 0 && (
                    <SoloJewelry id={character.jewelry.set_2} profile />
                  )}
                  {character.jewelry.set_3 != 0 && (
                    <SoloJewelry id={character.jewelry.set_3} profile />
                  )}
                </Col>
              </Row>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`rarity rar-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>artifact sets info aren't available yet.</strong> They
                  will be added soon.
                </p>
              </div>
            </>
          )}
          <div className={`content-header rarity rar-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Artifact Main Stats
          </div>
          {character.mainStats ? (
            <>
              <Row
                xs={1}
                xl={2}
                xxl={4}
                className={`main-stats rar-${character.rarity}`}
              >
                <Col>
                  <div className="box">
                    <div className="stats-header">
                      <StaticImage
                        src="../images/solo/icons/Artifact_Helmet.png"
                        alt="Artifact Helmet"
                      />
                      <span>Helmet</span>
                    </div>
                    <div className="list-stats">
                      {character.mainStats.helmet}
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="box">
                    <div className="stats-header">
                      <StaticImage
                        src="../images/solo/icons/Artifact_Shoes.png"
                        alt="Artifact Shoes"
                      />
                      <span>Boots</span>
                    </div>
                    <div className="list-stats">
                      {character.mainStats.boots}
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="box">
                    <div className="stats-header">
                      <StaticImage
                        src="../images/solo/icons/Artifact_Bracelet.png"
                        alt="Artifact Bracelet"
                      />
                      <span>Bracelet</span>
                    </div>
                    <div className="list-stats">
                      {character.mainStats.bracelet}
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="box">
                    <div className="stats-header">
                      <StaticImage
                        src="../images/solo/icons/Artifact_Ring.png"
                        alt="Artifact Ring"
                      />
                      <span>Ring</span>
                    </div>
                    <div className="list-stats">{character.mainStats.ring}</div>
                  </div>
                </Col>
              </Row>
              <p className="info">
                <strong>Important!</strong> The other 4 equipment pieces aren't
                listed here as they always come with the same main stats. Also,
                early on Flat main pieces may perform better, but long-term you
                want to focus on percentage stats.
              </p>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`rarity rar-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>
                    artifact main stats info aren't available yet.
                  </strong>{' '}
                  They will be added soon.
                </p>
              </div>
            </>
          )}
          <div className={`content-header rarity rar-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Stat focus
          </div>
          {character.bestStats ? (
            <div className="best-stats">
              <p>
                Those are the stats you should focus on when building{' '}
                <strong className={`rarity rar-${character.rarity}`}>
                  {character.name}
                </strong>
                . So search for them on your sub-stats as a priority.
              </p>
              <>{renderRichText(character.bestStats, options)}</>
            </div>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`rarity rar-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>stat focus info isn't available yet.</strong> It will
                  be added soon.
                </p>
              </div>
            </>
          )}
          <div className={`content-header rarity rar-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Best Weapons
          </div>
          {character.weaponRec ? (
            <>
              <div className="info-box with-margin">
                <p>
                  For <strong className={`rarity rar-SSR`}>SSR</strong>{' '}
                  characters, you{' '}
                  <strong>always want to use their Signature/EX Weapon</strong>{' '}
                  - they are simply the best for them. Until you obtain the
                  signature weapon, just use the options listed below. The
                  priority is set from <strong>left to right</strong>. Also, If
                  you have a Signature/EX weapon for a Hunter that is not in
                  your team you can consider using it for the raw stats alone -
                  as long as it matches the character.
                </p>
              </div>
              <div className="weapon-container">
                {character.weaponRec.map((weapon, idx) => {
                  return (
                    <div key={idx} className={`single-weapon`}>
                      <SoloWeapon name={weapon.name} mode="icon" showLabel />
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <>
              <div className="info-box">
                <p>
                  <strong className={`rarity rar-${character.rarity}`}>
                    {character.name}
                  </strong>{' '}
                  <strong>best weapons info aren't available yet.</strong> They
                  will be added soon.
                </p>
              </div>
            </>
          )}
          <div className={`content-header rarity rar-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Video guides
          </div>
          <div className="info-box">
            <p>
              <strong className={`rarity rar-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>guide videos aren't available yet.</strong>
            </p>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297232"></div>
      </>
    </DashboardLayout>
  );
};

export default SoloCharacterDetails;

export const Head: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  return (
    <Seo
      title={character.name + ' Guide | Solo Leveling: Arise | Prydwen'}
      description={
        character.name +
        ' from Solo Leveling: Arise. Check their skills, reviews, ratings and build guides.'
      }
      image={character.cardImage}
    />
  );
};

export const pageQuery = graphql`
  query ($contentfulId: String) {
    currentUnit: allContentfulSoloCharacter(
      filter: { id: { eq: $contentfulId } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        ...SoloCharacterFieldsFragment
      }
    }
  }
`;
